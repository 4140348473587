import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    lazyLoad: 'ondemand',
};

class SeGallery extends Component {
    render() {
        const {
            SeGalleryNew
        } = this.props;
        return (
            <>
                
                        <div className="halfdiv halfrgt aboutus_slider halfdiv-se">
                            <div
                                id="myCarousel2"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <Slider
                                    {...settings}
                                    className="carousel-inner"
                                >
                                    {SeGalleryNew.map(SeGalleryImages => (
                                        <div
                                            className="item carousel-item active"
                                            key={SeGalleryImages.id}
                                        >
                                            <img
                                                className="margin-0"
                                                src={
                                                    SeGalleryImages.localFile
                                                        .childImageSharp.fluid
                                                        .src
                                                }
                                                loading="lazy"
                                                alt="title"
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                  
            </>
        );
    }
}

export default SeGallery;